import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "teachers/";

export default {
    get() {
        const token = auth.getSession();
        return axios.get(ENDPOINT, { headers: { token } });
    },

    selector() {
        return axios.get(ENDPOINT + "select/reg/list"); // <--- public!
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    getByName(name) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "name/" + name, { headers: { token } });
    },

    insert(teacher) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, teacher, { headers: { token } });
    },

    update(id, teacher) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, teacher, { headers: { token } });
    },

    updatePassword(id, password) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id + "/" + password, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};