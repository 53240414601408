import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#1976D2", // Default
                background: '#f3f3f3',
                images: "#ffffff",
            },
            dark: {
                primary: "#1E88E5", // Default
                background: '#202020',
                images: "#323232",
            },
        },
    },
});