import axios from 'axios';
import Cookies from 'js-cookie';
import end from './endpoint';
const ENDPOINT = end.PATH + 'auth/';

export default {
    signIn(internal, username, password) {
        const credentials = { username, password };
        if (internal) return axios.post(ENDPOINT + 'signin', credentials);
        else return axios.post(ENDPOINT + 'services', credentials);
    },

    signOut() {
        Cookies.remove('session');
        Cookies.remove('route');;
    },

    openSesion(session, route) {
        Cookies.set('session', session);
        Cookies.set('route', route);
    },

    // storage
    getSession() {
        return Cookies.get('session');
    },
    
    getRoute() {
        return Cookies.get("route");
    },

    // invoked
    profile() {
        const token = this.getSession();
        return axios.get(ENDPOINT + "profile", { headers: { token } });
    },

    permission() {
        const token = this.getSession();
        return axios.get(ENDPOINT + "authorization", { headers: { token } });
    },

    identity() {
        const token = this.getSession();
        return axios.get(ENDPOINT + "identity", { headers: { token } });
    },
}