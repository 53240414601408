<template>
  <div class="contain" style="height: 100%">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-fade-transition>
      <v-container v-show="compiler" fluid fill-height>
        <v-row justify="center">
          <v-col style="width: 100%; max-width: 390px">
            <v-img
              lazy-src="../assets/images/default.png"
              :src="profile"
              contain
              height="70"
            ></v-img>
            <v-form ref="form" class="mt-7">
              <v-autocomplete
                v-model="username"
                :items="teachers"
                item-value="username"
                item-text="username"
                label="Docente"
                outlined
                clearable
                hide-details
                menu-props="auto"
                no-data-text="No hay docentes"
              ></v-autocomplete>
              <v-text-field
                v-model="password"
                v-on:keyup.enter="signIn()"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                label="Contraseña"
                outlined
                hide-details
                class="my-4"
              ></v-text-field>
              <v-expand-transition>
                <div v-show="alert">
                  <v-alert
                    type="error"
                    transition="fade-transition"
                    outlined
                    text
                    dense
                  >
                    <div class="text-subtitle-2">{{ error }}</div>
                  </v-alert>
                </div>
              </v-expand-transition>
            </v-form>
            <v-btn
              @click="signIn()"
              color="primary"
              dark
              block
              large
              elevation="0"
            >
              <div class="text-subtitle-2 mx-3" style="text-transform: none">
                Iniciar sesión
              </div>
            </v-btn>
            <div class="text-center text-subtitle-2 text--secondary mt-6">
              <a href="http://xdr.com.mx/" target="_blank">© xdr</a> -
              {{ school }}
              <span class="text--disabled font-weight-bold">{{ version }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
  </div>
</template>

<script>
import endpoint from "../plugins/endpoint";
import auth from "../plugins/auth";
import settings from "../plugins/settings";
import teachers from "../plugins/teachers";

export default {
  name: "Login",

  created() {
    this.main();
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  data: () => ({
    school: "",
    profile: "",
    version: "",

    username: "",
    password: "",
    show: false,

    teachers: [],

    alert: false,
    error: "",
    loading: false,
    compiler: false,
  }),

  methods: {
    // start chain
    async main() {
      try {
        this.loading = true;
        await settings.getOne(1).then((response) => {
          this.school = response.data.school;
          this.profile = endpoint.BASE_PATH + response.data.profile_image;
          this.version = endpoint.version;

          this.getTeachers();
        });
      } catch {
        this.$router.push("/off");
      }
    },

    async getTeachers() {
      await teachers.selector().then((response) => {
        this.teachers = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    // invoked
    async signIn() {
      if (this.username != "") {
        if (this.password != "") {
          this.loading = true;

          await auth
            .signIn(true, this.username, this.password)
            .then((response) => {
              if (response.data.status == "ok") {
                auth.openSesion(response.data.token, "/");

                this.navigate(response.data.navtype);
              } else {
                this.showAlert(response.data.info);
              }
              this.loading = false;
            });
        } else {
          this.showAlert("Contraseña inválida.");
        }
      } else {
        this.showAlert("Seleccionar docente");
      }
    },

    showAlert(message) {
      this.error = message;
      this.alert = true;
      setTimeout(() => (this.alert = false), 3000);
    },

    navigate(type) {
      this.$refs.form.reset();
      this.compiler = false;
      if (type == 1 || type == 2) {
        this.$router.push("ad");
      } else {
        this.$router.push("op");
      }
    },
  },
};
</script>