import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "settings/";

export default {
    getOne(id) {
        return axios.get(ENDPOINT + id); // <--- public!
    },

    insert(settings) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, settings, { headers: { token } });
    },

    update(id, settings) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, settings, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },
};