<template>
  <v-app>
    <v-app-bar
      app
      elevate-on-scroll
      :style="{
        background: $vuetify.theme.themes[theme].background,
      }"
    >
      <v-app-bar-nav-icon class="ml-1">
        <v-avatar tile size="20">
          <v-img
            lazy-src="./assets/images/default.png"
            src="./assets/logo.svg"
            contain
          ></v-img>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="text-subtitle-1 font-weight-medium">
        CEi
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="setTheme()" icon v-bind="attrs" v-on="on">
            <v-icon>
              {{ dark ? "mdi-white-balance-sunny" : "mdi-brightness-6" }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ dark ? "Tema claro" : "Tema oscuro" }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-help-circle-outline </v-icon>
          </v-btn>
        </template>
        <span>Ayuda</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="signOut()" icon v-bind="attrs" v-on="on" class="mr-1">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Salir</span>
      </v-tooltip>
    </v-app-bar>
    <v-main
      :style="{
        background: $vuetify.theme.themes[theme].background,
      }"
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// --- ! CEi --- Control escolar integral.
// -- No respeta la vista views/controllers por que en general todo es un controller
// -- Vistas en la carpeta controllers (en admin y operative)

import auth from "./plugins/auth";
import settings from "./plugins/settings";

export default {
  name: "App",

  // !- El evento calculado en todos los controllers contiene el tema
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  // !- Se utiliza el mounted para inicializar los datos y variables
  mounted() {
    this.main();
  },

  data: () => ({
    dark: false,
  }),

  methods: {
    // --- Inicio ---
    async main() {
      try {
        // -- Obtener los datos (con id 1 --  siempre respetar)
        await settings.getOne(1).then((response) => {
          // - Variables para los colores
          this.$vuetify.theme.themes.light.primary =
            response.data.primary_light;
          this.$vuetify.theme.themes.dark.primary = response.data.primary_dark;

          // - Agregar evento y observador al cambio de tema
          const prefersdark =
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches;
          this.dark = prefersdark;
          this.$vuetify.theme.dark = prefersdark;

          window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (e) => {
              this.dark = e.matches;
              this.$vuetify.theme.dark = e.matches;
            });
        });
      } catch {
        this.$router.push("/off");
      }
    },

    // --- LLamadas ---

    // -- Cambiar tema
    setTheme() {
      this.dark = !this.dark;
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    // -- Cerrar sesión
    signOut() {
      this.$router.push(auth.getRoute());
      auth.signOut();
    },
  },
};
</script>
