var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{style:({
      background: _vm.$vuetify.theme.themes[_vm.theme].background,
    }),attrs:{"app":"","elevate-on-scroll":""}},[_c('v-app-bar-nav-icon',{staticClass:"ml-1"},[_c('v-avatar',{attrs:{"tile":"","size":"20"}},[_c('v-img',{attrs:{"lazy-src":require("./assets/images/default.png"),"src":require("./assets/logo.svg"),"contain":""}})],1)],1),_c('v-toolbar-title',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" CEi ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.setTheme()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.dark ? "mdi-white-balance-sunny" : "mdi-brightness-6")+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.dark ? "Tema claro" : "Tema oscuro"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline ")])],1)]}}])},[_c('span',[_vm._v("Ayuda")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.signOut()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}])},[_c('span',[_vm._v("Salir")])])],1),_c('v-main',{style:({
      background: _vm.$vuetify.theme.themes[_vm.theme].background,
    })},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }