<template>
  <div class="contain" style="height: 100%">
    <v-fade-transition>
      <v-container fluid fill-height>
        <v-row justify="center">
          <v-col style="width: 100%; max-width: 500px">
            <div class="text-center">
              <v-icon x-large>mdi-emoticon-sick-outline</v-icon>
              <div class="text-h5 text--disabled my-4">No disponible</div>
              <div class="text-caption font-weight-medium text--secondary">
                Disculpe las molestias, CEi se encuentra
                <span class="warning--text font-weight-bold">
                  temporalmente fuera de servicio </span
                >, puede deberse a manteniento o a una sobrecarga de trabajo,
                por favor espere y si el problema persiste, comuníquelo a su
                administración escolar
              </div>
              <v-btn
                @click="reconect()"
                large
                color="primary"
                dark
                elevation="0"
                class="mt-5"
              >
                <div class="text-subtitle-2 mx-4" style="text-transform: none">
                  Probar nuevamente
                </div>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: "Offline",

  created() {
    this.main();
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  data: () => ({}),

  methods: {
    // start chain
    async main() {},

    reconect() {
      this.$router.push("/");
    },
  },
};
</script>